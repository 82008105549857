// @flow

import React from 'react'
import { FormInput, Select, InputZipByCountry } from 'src/common/components'
import { Field } from 'redux-form'
import { required, validateLength } from 'src/helpers/formValidation'
import { normalizeText } from 'src/helpers/formNormalizers'
import * as countriesConfig from 'src/config/countries'
import { COUNTRY_CODES } from 'src/common/constants'

const AddressBlock = ({
  prefix,
  title,
  change,
  country,
  errorMessage,
}: {
  prefix: string,
  title: string,
  change: (selector: string, item: string) => void,
  country: string,
  errorMessage?: string
}) => {
  return (
    <div>
      <h4>{title}</h4>
      {errorMessage && <p className="form-error">{errorMessage}</p>}
      <div className="form-row">
        <div className="form-group">
          <label className="required">Street Address</label>
          <div>
            <Field
              name={`${prefix}address_1`}
              id={`${prefix}address_1`}
              component={FormInput}
              normalize={normalizeText}
              validate={[required, validateLength('address')]}
              placeholder="Street building"
            />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <div>
            <Field
              name={`${prefix}address_2`}
              id={`${prefix}address_2`}
              component={FormInput}
              normalize={normalizeText}
              validate={[validateLength('address')]}
              placeholder="Apt Suite etc (optional)"
            />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <label className="required">City</label>
          <div>
            <Field
              name={`${prefix}city`}
              id={`${prefix}city`}
              component={FormInput}
              normalize={normalizeText}
              validate={[required, validateLength('city')]}
            />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <div className="form-row without-margin">
            <div className="form-group">
              <label className="required">
                { country === COUNTRY_CODES.CA ? 'Province' : 'State' }
              </label>
              <div>
                <Field
                  id={`${prefix}state`}
                  name={`${prefix}state`}
                  component={Select}
                  selectData={country === COUNTRY_CODES.CA ? countriesConfig.provinces : countriesConfig.states}
                  validate={[required]}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="required">Zip Code</label>
              <div>
                <InputZipByCountry
                  id={`${prefix}zip`}
                  name={`${prefix}zip`}
                  country={country}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <label>Country</label>
          <div>
            <Field
              id={`${prefix}country`}
              name={`${prefix}country`}
              component={Select} selectData={countriesConfig.countries}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddressBlock
